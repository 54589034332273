<template>
  <div>
    <b-card no-title>
      <b-row>
        <b-col
            xs="12"
            class="mx-auto"
        >
          <b-card-title>
            {{ $t('card.title.modify.legalPerson') }}
            <div v-if="this.$acl.canDeletePermission(role) && formData.deletable" class="float-right mr-1">
              <b-button
                  variant="danger"
                  size="sm"
                  v-b-tooltip.hover
                  :title="$t('label.button.delete')"
                  @click="deleteEntity"
              >
                <span v-if="!this.$helpers.isMobileResolution() && !this.$helpers.isSmallResolution()"
                      class="text-nowrap">{{ $t('label.button.delete') }}</span>
                <feather-icon v-else icon="XCircleIcon"/>
              </b-button>
            </div>
          </b-card-title>
        </b-col>
      </b-row>
      <b-card-text>
        {{ $t('card.description.modify.legalPerson') }}
      </b-card-text>
    </b-card>
    <b-overlay :show="loading">
      <b-card-code>
        <!-- form -->
        <validation-observer ref="simpleRules">
          <b-form>
            <b-row>
              <b-col md="6">
                <b-form-group
                    :label="$t('form.name')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.name').toLowerCase()"
                      rules="required"
                      vid="name"
                  >
                    <b-form-input
                        v-model="formData.name"
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.name').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                    :label="$t('form.notificationFrequency')"
                >
                  <validation-provider
                      #default="{ errors }"
                      :name="$t('form.notificationFrequency').toLowerCase()"
                      rules="required"
                      vid="notificationFrequency"
                  >
                    <b-form-input
                        v-model="formData.notificationFrequency"
                        type="number"
                        step="1"
                        readonly
                        :state="errors.length > 0 ? false:null"
                        :placeholder="$t('form.notificationFrequency').toLowerCase()"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <!-- submit button -->
              <b-col class="mt-1">
                <b-button
                    variant="primary"
                    type="submit"
                    class="float-right"
                    @click.prevent="submitForm"
                >
                  {{ $t('label.button.save') }}
                </b-button>
                <cancel-and-g-o-t-o-previous-page class="mr-2"/>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </b-card-code>
    </b-overlay>
    <b-card-code>
      <tabs
          ref="tabs"
          :entity-id="parseInt(this.$route.params.id)"
      />
    </b-card-code>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {
  BFormInput, BFormGroup, BCard, BCardTitle, BCardText, BForm, BRow, BCol, BButton, BFormCheckbox, BOverlay, VBTooltip
} from 'bootstrap-vue'
import {
  required, email,
} from '@validations'
import vSelect from 'vue-select'
import tabs from '@/views/pages/_components/_tabs/_legalPerson/update.vue'
import cancelAndGOTOPreviousPage from '@/views/pages/_components/_helper/cancelAndGOTOPreviousPage'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BCard,
    BCardTitle,
    BCardText,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormCheckbox,
    BOverlay,
    vSelect,
    tabs,
    cancelAndGOTOPreviousPage,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  data() {
    return {
      role: 'legalPerson',
      formData: {
        id: null,
        name: '',
        notificationFrequency: '',
        deletable: false
      },
      loading: false,
      required,
    }
  },
  created() {
    this.loading = false
    this.$store.dispatch('fetchLegalPerson', this.$route.params.id).then(response => {
      this.formData = response.data.entity
      this.formData.id = this.$route.params.id
    }).catch(error => {
      this.$helpers.showErrorToast(error.response.data.message)
      this.$acl.canListPermission(this.role)
          ? this.$router.push({name: 'legal-persons'})
          : this.$router.go(-1)
    }).finally(() => {
      this.loading = false
    })
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // clone formData
          let fd = JSON.parse(JSON.stringify(this.formData))

          this.$store.dispatch('updateLegalPerson', fd).then(() => {
            this.$helpers.showSuccessToast()

            this.$acl.canListPermission(this.role)
                ? this.$router.push({name: 'legal-persons'})
                : this.$router.go(-1)
          }).catch(error => {
            if (error.response.status === 422) {
              this.$refs.simpleRules.setErrors(error.response.data.errors)
            } else {
              this.$helpers.showErrorToast(error.toString())
            }
          })
        }
      })
    },
    deleteEntity() {
      this.$bvModal
          .msgBoxConfirm(this.$t('confirm.message.delete'), {
            title: this.$t('modal.title.confirm'),
            size: 'sm',
            okVariant: 'primary',
            okTitle: this.$helpers.ucFirst(this.$t('label.yes')),
            cancelTitle: this.$helpers.ucFirst(this.$t('label.no')),
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              const id = this.$route.params.id
              this.$store.dispatch('deleteLegalPerson', id).then(() => {
                this.$helpers.showSuccessToast(this.$t('text.api.response.deleteSuccessfully', {id}))

                this.$acl.canListPermission(this.role)
                    ? this.$router.push({name: 'legal-persons'})
                    : this.$router.go(-1)
              })
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
